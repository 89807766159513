import React from "react"
import "../styles/contact.css"

class ContactUs extends React.Component {
    render(){
        return (
            <div id="contact">
                <div className="contact-info">
                    <div className="line">
                        <img src="/media/amax-logo-contact.png" alt="Amax Enterprises Contact Logo" className="logo" />
                    </div>
                    <div className="line">
                        <strong>Address:</strong>
                        <span><a href="https://g.co/maps/8kktj" target="_blank" rel="noopener noreferrer">Flat 2, 13/F, South China Ind. Bldg., 1 Chun Pin Street, Kwai Chung, N.T., Hong Kong.</a></span>
                    </div>
                    <div className="line">
                        <strong>Contact:</strong>
                        <span>Mr. Jacky Cheng</span>
                    </div>
                    <div className="line">
                        <strong>Tel:</strong>
                        <span>(852) 24280202</span>
                    </div>
                    <div className="line">
                        <strong>Fax:</strong>
                        <span>(852) 24280070</span>
                    </div>
                    <div className="line">
                        <strong>E-mail:</strong>
                        <span><a href="mailto:&#106;&#097;&#099;&#107;&#121;&#064;&#097;&#109;&#097;&#120;&#045;&#116;&#097;&#098;&#108;&#101;&#119;&#097;&#114;&#101;&#115;&#046;&#099;&#111;&#109;">&#106;&#097;&#099;&#107;&#121;&#064;&#097;&#109;&#097;&#120;&#045;&#116;&#097;&#098;&#108;&#101;&#119;&#097;&#114;&#101;&#115;&#046;&#099;&#111;&#109;</a></span>
                    </div>
                </div>
                <iframe title="Company address on Google Map" src="https:s://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2193.856062838204!2d114.13598535351147!3d22.368297443842746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f89b461aaaab%3A0x1ba384bc870728f6!2ssouth%20china%20industrial%20building!5e0!3m2!1sja!2shk!4v1579079100832!5m2!1sja!2shk" width="350" height="350" frameBorder="0" style={{border:0}} allowFullScreen=""></iframe>
            </div>
        )
    }
}

export {ContactUs}
