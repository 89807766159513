import React from "react"
import {Link} from "react-router-dom"
import "../styles/product-list-item.css"
import {Constants} from "../_constants"
import cssEscape from "css.escape"

class Highlights extends React.PureComponent {

    render(){
        console.log(this.props.products)
        return (
            <div id="products">
                {this.props.products.map((value, index) => {
                    console.log(value)
                    let bgImage =`url(/media/product-thumbnails/${value.category}/${cssEscape(value.id)}.jpg)`
                    return (
                        <div className="product-list-item" key={value.id}>
                            <div className="text-description"><Link to={`/product/${value.category}/${value.id}`}>{value.name}</Link></div>
                            <div className={"overlay-circle circle-color"+(index%Constants.CIRCLE_TYPES+1)}></div>
                            <div className="product-image" style={{backgroundImage:bgImage}} />
                        </div>
                    )
                })}
            </div>
        )
    }
}

export {Highlights}
