import React from 'react'
import {Link} from "react-router-dom"
import Slider from "react-slick";
import {ShowroomManager} from "../_services"
import {NavigationBlocks} from "../_components"
import cssEscape from "css.escape"
import "../styles/home.css"

class Home extends React.PureComponent {

    constructor(props){
        super(props)
        this.state = {
            categories: null
        }
    }

    componentDidMount(){
        ShowroomManager.getCategories().then(response => {
            this.setState({
                categories: response.data.data.attributes.categories
            })
        })
    }

    render(){
        let sliderSettings = {
            dots: false, infinite: (this.state.categories != null && this.state.categories.length > 3), speed: 500, slidesToShow: 4,
            slidesToScroll: 4, initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div id="home">
                <div id="banner-a">
                    <div className="slide" />
                    <div className="slide" />
                    <div className="slide" />
                    <div className="slide" />
                    <div className="slide" />
                </div>
                <div className="inner-content">
                    <div id="banner-b">
                        <span>Drinkware Expertise Since 1998 - We always ready to offer you best service</span>
                    </div>
                    <div id="category-list">
                        {(this.state.categories != null && this.state.categories.length > 0) ? (
                            <Slider {...sliderSettings}>
                            {this.state.categories.map((value,index) => {
                                let bgImage =`url(/media/product-thumbnails/${value.id}/${cssEscape(value.featured)}.jpg)`
                                return(
                                    <div key={`${value.id}`}>
                                        <Link to={`/products/${value.id}`}>
                                            <div className="categoryImage" style={{backgroundImage:bgImage}} /><br/>
                                            <div className="legend">{value.name}</div>
                                        </Link>
                                    </div>
                                )
                            })}
                            </Slider>) : ""
                        }
                    </div>
                    <div id="factory-images" />
                    <div id="factory-cert-disclaimer">
                        <div className="home-cert-blocks">
                            <a href="/media/certificates-and-reports/01_BSCI_Audit_Report.pdf" target="_blank">
                                <img src="/media/home/cert-amfori.jpg" alt="amfori BSCI Factory Audit Compliant" />
                            </a>
                        </div>
                        <div className="home-cert-blocks">
                            <a href="/media/certificates-and-reports/02_Sedax_CAPR.pdf" target="_blank">
                                <img src="/media/home/cert-sedex.jpg" alt="Sedex Members Ethical Trade Audit Compliant" />
                            </a>
                        </div>
                        <div className="home-cert-blocks">
                            <a href="/media/certificates-and-reports/03_Disney_FAMA.pdf" target="_blank">
                                <img src="/media/home/cert-disney.jpg" alt="We are a Disney Authorized Factory for North America and France. Authorized FAMA No.: W615-4872-5" />
                            </a>
                        </div>
                        <div className="home-cert-blocks">
                            <a href="/media/certificates-and-reports/06_walmart_factory_audit_2017.pdf" target="_blank">
                                <img src="/media/home/cert-walmart.jpg" alt="Authorized factory audit by Walmart US ID no.: 36119217" />
                            </a>
                        </div>
                    </div><br style={{clear:"both"}} />
                    <NavigationBlocks />
                </div>
            </div>
        )
    }
}

export {Home}
