import React from 'react'
import "../styles/about.css"

class About extends React.PureComponent {
    render(){
        return(
            <div id="about">
            <div className="banner"></div>
            <div className="content">
                <div className="inner-content">
                    <h2>Our Story</h2>
                    <p>Amax Enterprises (HK) Limited is a subsidiary of Wuyi Shunfeng Stainless Steel Products Company Limited. We have 2 production factory sites at Wuyi, Zhejiang Province, having the most comprehensive production lines for stainless steel products that will cater for your needs. We offer very competitive price in the market with promise of timely delivery.</p>
                    <p>Our main products are stainless steel drinkware items including travel mugs, coffee mugs, sport bottles, vacuum flasks, coffee pots and bar wares. Our clients include world-renowned brands. In addition to the major markets in Europe and the United States, our sales network extends worldwide.</p>
                    <p>Safety and stringent quality control are our prime focus, as such all our products meet FDA and European food safety requirements. Superior standards of our products have been recognized and accredited with our plant passing factory audits conducted by several of our customers, namely Walt Disney, CVS Pharmacy, Amazon, etc.</p>
                    <p>Our Research and Development team with creative thinking continue to design new products that meet and satisfy the market needs. OEM can also be done in our factory. If you desire to develop new products, please feel free to contact us for sharing our best knowledge and experience. We will be pleased to explore new markets with you and bring our collaboration to success.</p>
                    <h5>We are ready to offer you our best service.</h5>
                </div>
                <h2>Major Buyers</h2>
                <div id="our-clients">
                    <div><img src="/media/about-us/client1.jpg" title="Walt Disney" alt="Client: Walt Disney" /></div>
                    <div><img src="/media/about-us/client2.jpg" title="Walmart" alt="Client: Walt Disney" /></div>
                    <div><img src="/media/about-us/client3.jpg" title="CVS Pharmacy" alt="Client: CVS Pharmacy" /></div>
                    <div><img src="/media/about-us/client4.jpg" title="BIG LOTS!" alt="Client: BIG LOTS!" /></div>
                    <div><img src="/media/about-us/beaba.jpg" title="BÉABA" alt="Client: BÉABA" /></div>
                    <div><img src="/media/about-us/client6.jpg" title="NESCAFÉ" alt="Client: NESCAFÉ" /></div>
                    <div><img src="/media/about-us/client7.jpg" title="BED BATH & BEYOND" alt="Client: BED BATH & BEYOND" /></div>
                    <div><img src="/media/about-us/client8.jpg" title="Amazon" alt="Client: Amazon" /></div>
                    <div><img src="/media/about-us/client9.jpg" title="SPORTSCAVe" alt="Client: SPORTSCAVe" /></div>
                    <div><img src="/media/about-us/client10.jpg" title="THERMOCafé by THERMOS" alt="Client: THERMOCafé by THERMOS" /></div>
                    <div><img src="/media/about-us/client11.jpg" title="SPORTS Unlimited" alt="Client: SPORTS Unlimited" /></div>
                    <div><img src="/media/about-us/client12.jpg" title="WILLIAMS-SONOMA, INC." alt="Client: WILLIAMS-SONOMA, INC." /></div>
                </div>
            </div>
            </div>
        )
    }
}

export {About}
