import axios from "axios"

const API_PREFIX = "https:s://www.amax-tablewares.com/index.php/api"

export const ShowroomManager = {
    getCategories: (hasNewProducts) => {
        return axios.get(API_PREFIX + (hasNewProducts ? "/new-categories" : "/categories"))
    },
    getProducts: (catId, isNew) => {
        return axios.get(API_PREFIX + (isNew ? "/new-products/" : "/products/") + catId)
    },
    getProduct: (catId, productId) => {
        return axios.get(API_PREFIX + "/product/" + catId + "/" + productId.replace(/&#040;/g, "(").replace(/&#041;/g, ")"))
    },
    getHighlights: () => {
        return axios.get(API_PREFIX + "/highlights");
    }
}
