import React from "react"
import AbstractDualPurposeBlocks from "./_abstract-dual-purpose-blocks"
import "../styles/navigation-blocks.css"

class CertBlocks extends AbstractDualPurposeBlocks {

    constructor(props){
        super({
            blockId : "certification-blocks"
        });
    }

    content(){
        return [
            <div key="certBlock1" id="cert-block1" className="cert-preview odd">
                <a href="/media/certificates-and-reports/01_BSCI_Audit_Report.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">BSCI Factory Audit Report</div>
                </a>
            </div>,
            <div key="certBlock2" id="cert-block2" className="cert-preview even">
                <a href="/media/certificates-and-reports/02_Sedax_CAPR.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">Sedex Factory Audit Report</div>
                </a>
            </div>,
            <div key="certBlock3" id="cert-block3" className="cert-preview odd">
                <a href="/media/certificates-and-reports/03_Disney_FAMA.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">Disney FAMA</div>
                </a>
            </div>,
            <div key="certBlock4" id="cert-block4" className="cert-preview even">
                <a href="/media/certificates-and-reports/04_Disney_Shanghai_FAMA.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">Disney Shanghai FAMA</div>
                </a>
            </div>,
            <div key="certBlock5" id="cert-block5" className="cert-preview odd">
                <a href="/media/certificates-and-reports/05_Universal_Studios_Factory_Audit_Report.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">Universal Studios Factory Audit Report</div>
                </a>
            </div>,
            <div key="certBlock6" id="cert-block6" className="cert-preview even">
                <a href="/media/certificates-and-reports/06_walmart_factory_audit_2017.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">Walmart Factory Audit Certificate</div>
                </a>
            </div>,
            <div key="certBlock7" id="cert-block7" className="cert-preview odd">
                <a href="/media/certificates-and-reports/07_Factory_Audit_Certification_by_CVS_Health.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">US - CVS Health Factory Audit Certificate</div>
                </a>
            </div>,
            <div key="certBlock8" id="cert-block8" className="cert-preview even">
                <a href="/media/certificates-and-reports/08_QS_Certificate.jpg" target="_blank">
                    <div className="background" />
                    <div className="caption">QS Certificate - China Market</div>
                </a>
            </div>,
            <div key="certBlock9" id="cert-block9" className="cert-preview odd">
                <a href="/media/certificates-and-reports/09_Testing_Report_by_FDA.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">FDA Certificate</div>
                </a>
            </div>,
            <div key="certBlock10" id="cert-block10" className="cert-preview even">
                <a href="/media/certificates-and-reports/10_Testing_Report_by_China_Regulation.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">China Regulation Certificate</div>
                </a>
            </div>,
            <div key="certBlock11" id="cert-block11" className="cert-preview odd">
                <a href="/media/certificates-and-reports/11_Testing_Report_by_EU.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">EU Regulation Certificate</div>
                </a>
            </div>,
            <div key="certBlock12" id="cert-block12" className="cert-preview even">
                <a href="/media/certificates-and-reports/12_VOM_testing_report_by_Intertek_Thermos.pdf" target="_blank">
                    <div className="background" />
                    <div className="caption">VOM Silicone Ring Certificate in French</div>
                </a>
            </div>
        ]
    }
}

export {CertBlocks}
