import React from 'react'
import {Link} from 'react-router-dom'
import "../styles/navfooter.css"

class NavFooter extends React.PureComponent {

    render(){
        return (
            <div id="nav-footer">
                <ul>
                    <li id="footer-link-home"><Link to="/"><span className="fa fa-home" aria-hidden="true"></span></Link></li>
                    <li id="footer-link-3"><Link to="/contact-us"><span className="fa fa-whatsapp" aria-hidden="true"></span></Link></li>
                </ul>
            </div>
        )
    }
}

export {NavFooter}
