import React from 'react'
import {Link} from "react-router-dom"
import {ShowroomManager} from "../_services"
import {Constants} from "../_constants"
import cssEscape from "css.escape"
import "../styles/product-list.css"
import "../styles/category-list-item.css"

class CategoryList extends React.PureComponent{

    constructor(props){
        super(props)
        this.state = {
            categories: []
        }
    }

    componentDidMount(){
        ShowroomManager.getCategories().then(response => {
            this.setState({
                categories: response.data.data.attributes.categories
            })
        })
    }

    render(){
        return(
            <div id="category-list">
                <div className="banner">
                    <span>Drinkware Expertise Since 2001</span>
                </div>
                <div className="content">
                    <div id="categories">
                    {this.state.categories.map((value, index) => {
                        let bgImage =`url(/media/product-thumbnails/${value.id}/${cssEscape(value.featured)}.jpg)`
                        return (
                            <div className="category-list-item" key={value.id}>
                                <div className="text-description"><Link to={`/products/${value.id}`}>{value.name}</Link></div>
                                <div className={"overlay-circle circle-color"+(index%Constants.CIRCLE_TYPES+1)}></div>
                                <div className="product-image" style={{backgroundImage:bgImage}} />
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
        )
    }
}

export {CategoryList}
