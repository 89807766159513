import React from 'react'

class Footer extends React.PureComponent{

    render(){
        return(
            <div id="footer">
                <span>&copy; 2004-2020 Amax Enterprises(HK) Limited. All rights reserved. Logos, company and product names which may shown on photos are trademarks or registered trademarks of their respective companies.</span>
            </div>
        )
    }
}

export {Footer}
