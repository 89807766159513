import React from 'react'
import {CertBlocks} from "../_components"
import "../styles/cert.css"

class Cert extends React.PureComponent {
    render(){
        return (
            <div id="certifications-and-reports">
                <div className="banner">
                    <span>Certifications & Reports</span>
                </div>
                <div className="content">
                    <CertBlocks />
                </div>
            </div>
        )
    }
}

export {Cert}
