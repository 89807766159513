import React from 'react'
import {Highlights} from "../_components"
import {ShowroomManager} from "../_services"
import "../styles/product-list.css"

class HighlightList extends React.PureComponent{

    constructor(props){
        super(props)
        this.state = {
            products: []
        }
    }

    componentDidMount(){
        ShowroomManager.getHighlights().then(response => {
            this.setState({products:response.data.data.attributes.products})
        })
    }

    render(){
        return(
            <div id="product-list" key="category_highlights">
                <div className="banner">
                    <span>Highlights</span>
                </div>
                <div className="content">
                    <Highlights products={this.state.products} />
                </div>
            </div>
        )
    }
}

export {HighlightList}
