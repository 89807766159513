import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { NavBar, NavFooter, Footer } from "./_components";
import ScrollToTop from "./_components/scroll-to-top";
import { Home, About, ContactUs, Exhibitions, Cert, ProductList, HighlightList, ProductView, CategoryList } from "./_pages";

class App extends React.Component {
    render(){
        return (
          <Router>
              <ScrollToTop />
              <NavBar />
              <div id="content">
                  <Switch>
                      <Route exact path='/' component={Home} />
                      <Route path='/about-us' component={About} />
                      <Route path='/contact-us' component={ContactUs} />
                      <Route path='/certification-and-reports' component={Cert} />
                      <Route path="/categories" component={CategoryList} />
                      <Route path="/products/:catid?/:productid?" render={(props) => <ProductList {...props} />} />
                      <Route path='/highlights' render={(props) => <HighlightList {...props} />} />
                      <Route path='/product/:catid/:productid' render={(props) => <ProductView {...props} />} />
                      <Route path='/exhibitions' component={Exhibitions} />
                      <Redirect from="*" to="/" />
                  </Switch>
              </div>
              <NavFooter />
              <Footer />
          </Router>
        );
    }
}

export default App;
