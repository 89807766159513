import React from "react"
import {Link} from "react-router-dom"
import AbstractDualPurposeBlocks from "./_abstract-dual-purpose-blocks"
import "../styles/navigation-blocks.css"

class NavigationBlocks extends AbstractDualPurposeBlocks {

    constructor(props){
        super({
            blockId : "navigation-blocks"
        });
    }

    content(){
        return [
            <div key="navBlock3" className="block" id="nav-block3">
                <div className="background" />
                <div className="caption"><Link to="/about-us">Our Story</Link></div>
            </div>,
            <div key="navBlock2" className="block" id="nav-block2">
                <div className="background" />
                <div className="caption"><Link to="/categories">Drinkware</Link></div>
            </div>,
            <div key="navBlock1" className="block" id="nav-block1">
                <div className="background" />
                <div className="caption"><Link to="/certification-and-reports">Certifications & Reports</Link></div>
            </div>,
            <div key="navBlock6" className="block" id="nav-block6">
                <div className="background" />
                <div className="caption"><Link to="/exhibitions">Trade Fairs</Link></div>
            </div>,
            <div key="navBlock5" className="block" id="nav-block5">
                <div className="background" />
                <div className="caption"><Link to="/highlights">Highlights</Link></div>
            </div>,
            <div key="navBlock4" className="block" id="nav-block4">
                <div className="background" />
                <div className="caption"><a href="mailto:jacky@amax-tablewares.com">Enquiry</a></div>
            </div>
        ]
    }

    render(){
        return super.render()
    }
}

export {NavigationBlocks}
