import React from 'react'
import {Link} from "react-router-dom"
import "../styles/navbar.css"
import "../styles/header.css"

class NavBar extends React.PureComponent {

    render(){
        return (
            <header id="head-banner">
                <div className="site-branding">
                    <div className="logo">
                        <img src="/media/amax-logo.png" alt="AMAX Enterprise Logo" />
                        <label htmlFor="hamburger"><span className="fa fa-bars" aria-hidden="true"></span></label>
                    </div>

                </div>
                <nav id="navbar">
                    <input type="checkbox" id="hamburger" />
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">Our Story</Link></li>
                        <li><Link to="/categories">Drinkware</Link></li>
                        <li><Link to="/highlights">Highlights</Link></li>
                        <li><Link to="/certification-and-reports">Certifications</Link></li>
                        <li><Link to="/exhibitions">Trade Fairs</Link></li>
                        <li><Link to="/contact-us">Contact</Link></li>
                    </ul>
                </nav>
            </header>
        )
    }
}

export {NavBar}
