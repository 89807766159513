import React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class AbstractDualPurposeBlocks extends React.PureComponent {

    constructor(props){
        super(props);
        this.state = { width: 0, height: 0, blockId: props.blockId };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    carousel(){
        let navSliderSettings = {
            dots: false, infinite: true,
            slidesToScroll: 1, initialSlide: 0,
        }
        return(
            <Slider {...navSliderSettings}>{this.content()}</Slider>
        )
    }

    fluidGrid(){
        return (
            <div className="fluid-grid">{this.content()}</div>
        )
    }

    content() {
        return []
    }

    render(){
        return(
            <div id={this.state.blockId}>
            {
                this.state.width <= 480 ? this.carousel() : this.fluidGrid()
            }
            </div>
        )
    }
}

export default AbstractDualPurposeBlocks
