import React from 'react'
import "../styles/exhibitions.css"

class Exhibitions extends React.PureComponent {

    render(){
        return (
            <div id="exhibitions">
                <div className="banner">
                    <span>Trade Fairs / Exhibitions</span>
                </div>
                <div className="content">
                    <div className="details">
                        <h4><a href="https:s://www.mega-show.com/en-asiangnp-show-intro.php" target="_blank" rel="noopener noreferrer">Asian Gifts & Premiums Show</a></h4>
                        <p>Date : 20-23 October 2020<br/>
                        <a href="https:s://www.hkcec.com" target="_blank" rel="noopener noreferrer">Hong Kong Convention & Exhibition Centre</a><br/>
                        Booth No. : 3C-E33 (Hall 3C)</p>

                        <h4><a href="https:s://www.mega-show.com/en-asiangnt-show-intro.php" target="_blank" rel="noopener noreferrer">Asian Gifts & Travel Goods Show</a></h4>
                        <p>Date : 27-29 October 2020<br/>
                        <a href="https:s://www.hkcec.com" target="_blank" rel="noopener noreferrer">Hong Kong Convention & Exhibition Centre</a><br/>
                        Booth No. : 3G-A18 (Hall 3G)</p>

                        <h4><a href="https:s://event.hktdc.com/fair/hktoyfair-en/HKTDC-Hong-Kong-Toys-and-Games-Fair/" target="_blank" rel="noopener noreferrer">HKTDC Hong Kong Toys & Games Fair 2021</a></h4>
                        <p>Date : 11-14 January 2021<br/>
                        <a href="https:s://www.hkcec.com" target="_blank" rel="noopener noreferrer">Hong Kong Convention & Exhibition Centre</a><br/>
                        Booth No. : 3C-E06 (Hall 3C)</p>

                        <h4>HKTDC Hong Kong Gifts & Premium Fair 2021</h4>
                        <p>Date : 27-30 April 2021<br/>
                        <a href="https:s://www.hkcec.com" target="_blank" rel="noopener noreferrer">Hong Kong Convention & Exhibition Centre</a><br/>
                        Booth No. : 1E-C35 (Hall 1E)</p>
                    </div>
                </div>
            </div>
        )
    }
}

export {Exhibitions}
