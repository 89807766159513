import React from 'react'
import {ShowroomManager} from "../_services"
import {Link} from "react-router-dom"
import { Carousel } from 'react-responsive-carousel';
import {Table, Thead, Tbody, Tr, Th, Td} from '../_components/react-super-responsive-table';
import '../_components/react-super-responsive-table/SuperResponsiveTableStyle.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/product-view.css"

class ProductView extends React.PureComponent{

    constructor(props){
        super(props)
        this.state = {
            product: null,
            productDesc: null,
            isFamily: false,
            itemCount: 1
        }
        this.constructCarousel = this.constructCarousel.bind(this)
        this.constructTable = this.constructTable.bind(this)
    }

    componentDidMount(){
        ShowroomManager.getProduct(this.props.match.params.catid, this.props.match.params.productid).then(response => {
            let images = [];
            let videos = null;
            let lines = [];
            let itemCount = 1;
            for(let i=1;i<=response.data.data.attributes.product.imgCount;i++)
                images.push("/media/product-images/"+this.props.match.params.catid+"/"+(this.props.match.params.productid.replace(/&#040;/g, "(").replace(/&#041;/g, ")"))+"-"+i+".jpg");
            if(response.data.data.attributes.product.hasVideo && response.data.data.attributes.product.hasVideo > 0) {
                videos = [];
                for(let i=1;i<=response.data.data.attributes.product.hasVideo;i++)
                    videos.push("/media/product-images/"+this.props.match.params.catid+"/"+(this.props.match.params.productid.replace(/&#040;/g, "(").replace(/&#041;/g, ")"))+"-"+i+".mp4")
            }
            if(!response.data.data.attributes.product.isFamily) {
                let l = response.data.data.attributes.product.desc.split("\n");
                for(const i in l){
                    let line = l[i];
                    if(line !== null && line !== "") {
                        let row = line.split(":");
                        lines.push(row)
                    }
                }
            } else {
                let l = response.data.data.attributes.product.desc
                for(const i in l) {
                    let line = l[i];
                    if(i==0) {
                        itemCount = line.length-1
                    }
                    lines.push(line)
                }
            }

            this.setState({
                product:response.data.data.attributes.product,
                productDesc: lines,
                videos: videos,
                images: images,
                itemCount: itemCount
            })
        })
    }

    constructCarousel(){
        let retval = this.state.images.map((value,index) => {
            return (
                <div key={`${this.state.product.id}_${value}`}>
                    <img src={value} alt={`${this.state.product.name}`} />
                </div>
            )
        })
        if(this.state.videos != null && this.state.videos.length > 0) {
            retval = retval.concat(this.state.videos.map((value,index) => {
                return (
                    <div key={`${this.state.product.id}_v${value}`}>
                        <video width="100%" height="100%" controls autoPlay loop>
                            <img src={`${value}.thumbnail.jpg`} alt={`Video thumbnail for ${this.state.product.name}`} />
                            <source src={value} type="video/mp4" />
                        </video>
                    </div>
                )
            }))
        }
        return retval
    }

    constructTable() {
        let code = this.state.productDesc.splice(0,1)[0]
        return (<Table className="product-description">
            <Thead>
                <Tr key="product-code">{code.map((col,i)=><Th key={`th_${i}`}>{col}</Th>)}</Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Td colSpan="1">Product</Td>
                    <Td colSpan={this.state.itemCount}>{this.state.product.name}</Td>
                </Tr>
            {this.state.productDesc.map((row,i)=>{
                return(<Tr key={i}>{row.map((col,j)=>{
                    if(row.length===2)
                        return(<Td key={`td_${j}`} colSpan={j==0?1:this.state.itemCount}>{col}</Td>)
                    else
                        return(<Td key={`td_${j}`}>{col}</Td>)
                })}</Tr>)
            })}
            </Tbody>
        </Table>)
    }

    render(){
        let _constructCarousel = this.constructCarousel
        let _constructTable = this.constructTable
        return(
            <div id="product-view" key={this.props.match.params.productid}>
                <div className="banner">
                    <span>{(this.state.product != null) ? this.state.product.name : ""}</span>
                </div>
                <div className="content">{
                    (this.state.product != null) ? (
                        <div id="product-details">
                            <Carousel showIndicators={false} showStatus={false}>
                            {_constructCarousel()}
                            </Carousel>
                            {_constructTable()}
                        </div>
                    ) : ""
                }
                <p><Link to={`/products/${this.props.match.params.catid}`}>Return to Product List</Link></p>
                </div>
            </div>
        )
    }
}

export {ProductView}
