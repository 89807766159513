import React from 'react'
import {Link} from "react-router-dom"
import {Products} from "../_components"
import {ShowroomManager} from "../_services"
import "../styles/product-list.css"

class ProductList extends React.PureComponent{

    constructor(props){
        super(props)
        this.state = {
            products: [],
            category: null,
        }
    }

    componentDidMount(){
        ShowroomManager.getProducts(this.props.match.params.catid, false).then(response => {
            this.setState({products:response.data.data.attributes.products, category: response.data.data.attributes.category})
        })
    }

    render(){
        return(
            <div id="product-list">
                <div className="banner">
                    <span>{this.state.category != null ? this.state.category.name : "Drinkware Expert Since 2001"}</span>
                </div>
                <div className="content">
                    <Products key={this.props.match.params.catid} products={this.state.products} category={this.state.category} newProducts={false}  />
                    <br style={{clear: "both"}}/>
                    <p style={{float: "left", width: "100%"}}><Link to="/categories">Return to Drinkware Categories</Link></p>
                </div>
            </div>
        )
    }
}

export {ProductList}
